<template>
  <fixed-header :threshold="100">
    <div>
      <!-- <InfoBanner>
        <div class="text-xl">❄️ Christmas opening hours ❄️</div>
        <div class="text-base mt-1">
          23 Dec - 9am - 6pm | 24 Dec - 9am - 2pm | 25 Dec - Closed | 26 Dec -
          Closed | 27 Dec - 9am - 6pm | 28 Dec - Closed | 29 Dec - Closed | 30
          Dec - 9am - 6pm | 31 Dec - 9am - 4:30pm
        </div>
        <div class="text-base mt-1">1st Jan - Closed | 2nd Jan - 9am - 6pm</div>
      </InfoBanner> -->

      <nav class="navbar py-3 sm:py-6 mx-auto absolute w-full left-0 z-10">
        <div class="flex justify-between max-w-screen-xl mx-auto px-8 sm:px-6">
          <div class="flex items-center logo">
            <img
              src="../assets/images/logo.svg"
              alt="Unity"
              class="cursor-pointer flex-shrink"
              @click="$router.push({ name: 'home' })"
            />
          </div>
          <div class="items-center hidden space-x-8 lg:flex">
            <ul class="flex space-x-5 uppercase text-white">
              <li
                v-for="(link, i) in navigation"
                :key="i"
                class="cursor-pointer nav-item"
              >
                <router-link :to="link.route" class="">{{
                  link.name
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="flex">
            <Sidebar
              class="inline-flex"
              @dashboard-login="$emit('dashboard-login')"
              @apply-url="$emit('apply-url')"
            />
            <div class="items-center space-x-3 hidden md:flex">
              <Button
                @click.native="$emit('apply-url')"
                theme="primary"
                title="Apply for finance"
                >Apply</Button
              >
              <Button
                @click.native="$emit('dashboard-login')"
                theme="outlineYellow"
                title="Sign to your dashboard"
                >Sign In</Button
              >
              <div
                class="flex px-4 cursor-pointer hover:bg-opacity-90 transition ease-in-out"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="w-5 h-auto"
                >
                  <path
                    fill="#FFB040"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  />
                </svg>
                <a
                  :href="`tel:${telephone}`"
                  class="px-4 font-bold text-xl text-white"
                  :title="telephone"
                  >{{ telephone }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header'
import Button from '@/components/Unity/Button'
import Sidebar from '@/components/Unity/Sidebar'
import InfoBanner from '@/components/InfoBanner'

export default {
  name: 'Header',
  components: {
    Button,
    FixedHeader,
    Sidebar,
    InfoBanner,
  },
  data() {
    return {
      navigation: [
        { name: 'Our Way Of Life', route: '/our-way-of-life' },
        { name: 'HP Explained', route: '/hp-explained' },
      ],
      telephone: '0330 912 1002',
    }
  },
  methods: {
    unityHome() {
      return this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  min-width: 110px;

  @media (max-width: 768px) {
    img {
      width: 100px;
    }
  }
}

nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  .nav-item {
    position: relative;
    padding: 0 10px 0 10px;
    display: inline-block;
    border-bottom: 2px solid transparent;

    &:after {
      content: '';
      position: absolute;
      bottom: -42px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: transparent;
    }

    &:hover {
      &:after {
        background-color: #ec7358;
        transition: background-color ease-in-out 300ms;
      }
    }
  }
}

.vue-fixed-header--isFixed {
  @apply fixed left-0 top-0 z-10;

  width: 100vw;
  animation: topDown 1s ease-in-out;
  animation-delay: 10ms;
}

.vue-fixed-header--isFixed .navbar {
  @apply bg-unity-dark-grey;

  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  border-bottom: none;
  width: 100vw;
  opacity: 1;
}

@keyframes topDown {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
