<template>
    <main class="pcp-explained">

        <AprRepresentative />


        <section class="grid lg:grid-cols-2 pt-12 md:pt-0 xxl:max-w-screen-xl xxl:mx-auto">
            <div class="flex items-center p-8 lg:px-16 xxl:pl-0">
                <div>
                    <h4 class="hdr-lead mb-3">We provide HP finance</h4>
                    <h2 class="font-extrabold mb-8">So how does HP finance work?</h2>
                    <p>The original and most straightforward way of financing a car, a Hire Purchase agreement gives you the ability to pay off your vehicle over a fixed period without using an unsecured personal loan – with the vehicle being yours once that final monthly payment has been paid off.</p>

                    <p>This is a great solution for those looking to get – and keep – the keys to their car at the end of the agreement.
                    </p>

                    <!--<Button @click.native="$emit('apply-url')" theme="primary" title="Get An Instant Secure Quote" class="mt-8">-->
                    <!--    Get an instant quote-->
                    <!--</Button>-->

                </div>
            </div>

            <div>
                <img src="@/assets/images/iStock-1184170733.jpg" alt="" class="shrink object-cover w-full h-full" data-aos="fade-left" data-aos-duration="900" data-aos-delay="300" />
            </div>
        </section>

        <WhyHp @apply-url="$emit('apply-url')" />

        <section class="grid lg:grid-cols-2 bg-unity-dark-grey xxl:max-w-screen-xl xxl:mx-auto">
            <div>
                <img src="@/assets/images/iStock-1352185987.jpg" alt="" class="object-cover h-full" data-aos="fade-right" data-aos-duration="900" data-aos-delay="300" />
            </div>
            <div class="flex items-center p-8 lg:px-16 py-16">
                <div>
                    <h4 class="hdr-lead mb-3">Hire Purchase</h4>
                    <h3 class="font-extrabold mb-8 text-white">The straightforward way to finance a car</h3>

                    <p class="text-white">You are essentially hiring your car, normally over 2-5 years, until the last monthly payment is made, when you take full ownership - hence, Hire Purchase. This is also sometimes known as a ‘Lease Purchase’ in the finance world.</p>

                    <p class="text-white">Typically, you will place a 10%-40% deposit at the start of the agreement with a custom agreement length based on your current financial circumstances.</p>


                    <ul class="mt-8 text-white check-list">
                        <li>Pay all the monthly payments</li>
                        <li>Pay the Option To Purchase fee (£10)</li>
                        <li>Take full ownership of your car</li>
                    </ul>

                </div>
            </div>
        </section>

        <Information />
        <CallBackForm />

    </main>
</template>

<script>
import Button from "@/components/Unity/Button";
import Information from "@/components/Unity/Information";
import WhyHp from "@/components/Unity/WhyHp";
import CallBackForm from "@/components/CallBackForm";
import AprRepresentative from "@/components/Unity/AprRepresentative";

export default {
    name: "HpExplained",
    components: {
        AprRepresentative,
        CallBackForm,
        Button,
        Information,
        WhyHp
    },
    metaInfo: {
        title: 'HP explained',
    },
    data() {
        return {

        }
    },
    mounted() {
        this.$emit('update-banner-title', {
            title: 'HP Explained',
            subTitle: ''
        })
    }
}
</script>

<style scoped lang="scss">

</style>
