<template>
  <div
    ref="trustbox"
    class="trustpilot-widget"
    data-locale="en-GB"
    data-template-id="5419b6ffb0d04a076446a9af"
    data-businessunit-id="62b1db1307293511dd2bad53"
    data-style-height="20px"
    data-style-width="100%"
    data-theme="light"
  >
    <a
      href="https://www.trustpilot.com/review/unityauto.finance"
      target="_blank"
      rel="noopener"
    >
      Trustpilot
    </a>
  </div>
</template>

<script>
export default {
  name: "TrustBox",

  mounted() {
    const trustbox = this.$refs.trustbox;
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox);
    }
  },
}
</script>
