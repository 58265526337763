import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import "./scss/app.scss";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import './vee-validate/vee-validate';
import './vee-validate/vee-validate-rules';
import moment from 'moment';
import VueGtm from 'vue-gtm';
import VueAppInsights from 'vue-application-insights'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
	faBars
} from '@fortawesome/pro-light-svg-icons';

Vue.prototype.moment = moment
Vue.config.productionTip = false;

// Application Insights
Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_INSIGHTS_KEY,
    router
})

Vue.use(VueGtm, {
    id: 'GTM-MC69667',
    defer: true,
    enabled: false,
    debug: process.env.NODE_ENV !== 'production',
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
});

new Vue({
    router,
    render: (h) => h(App),
    mounted() {
        AOS.init({
            // Global settings:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        })
    },
}).$mount("#app");

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(
    faBars
)
dom.watch()
