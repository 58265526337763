<template>

    <div class="flex-col justify-between max-w-screen-xl lg:w-3/4 mx-auto text-center px-8 sm:px-6">
        <div class="w-full py-8">
            <TrustBox />
        </div>

        <p class="mx-auto mb-8 text-sm text-unity-text-grey">Representative Example APR 25.9% - Hire Purchase: Cash Price £10,000.00, Deposit £0, borrowing £10,000.00 over 5 years at 25.9% Representative APR (fixed). 59 monthly payments of £283.28 followed by followed by a final payment of £293.28 which includes a £10 option to purchase fee. Total cost of credit of £7006.80 and total amount payable of £17,006.80. Finance subject to status 21+ only.</p>
    </div>
</template>

<script>
import TrustBox from "@/components/TrustBox"

export default {
    name: "AprRepresentative",
    components: {
        TrustBox,
    },
}
</script>
